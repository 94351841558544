import {serializeOrders, serializeFilters } from '@/utils/params';

const mixin_list = {
  methods: {
    onPaging(pageEvent) {
      if (this.filterOptions) {
        this.filterOptions.page = pageEvent.page;
        this.filterOptions.size =pageEvent.rows;
      }
      if (typeof this._resetTimeout == 'function') {
        this._resetTimeout();
      }
		},
    onFilter(filterEvent) {
      if (this.filterOptions) {
        let filter = serializeFilters(filterEvent);
        if (this.filterOptions.filter != filter) {
          this.filterOptions.filter = filter;
          if (typeof this._resetTimeout == 'function') {
            this._resetTimeout();
          }
        }
      }
    },
    onSort(sortEvent) {
      if (this.filterOptions) {
        let sorts = serializeOrders(sortEvent);
        if (this.filterOptions.sorts != sorts) {
          this.filterOptions.sorts = serializeOrders(sortEvent);
          if (typeof this._resetTimeout == 'function') {
            this._resetTimeout();
          }
        }
      }
    },
  }
}
export default mixin_list;
